<!--<div style="padding-top: 100px;">
    <div class="error-div">
      <h2>You do not have access to this system.</h2>
      <h3>Please contact the Helpdesk.</h3>-->
      <!-- <h5>You do not have access to this system. Please contact the Helpdesk</h5> -->
    <!--</div>
  </div>-->

<div class="container">
  <div class="access-image">
  </div>
  <div style="text-align: center; color: #666">
    <h2>You do not have access to this system.</h2>
    <h3>Please contact the Helpdesk.</h3>
  </div>
</div>
