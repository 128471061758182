<div class="img-description bg-color box-container-shadow">
  <div style="float:left;margin-top:10px;">
    <span *ngIf="oracleEntity" class="heading">{{entitySourceId}}: {{oracleEntity.description}}</span>
  </div>
  <div style="float: right;">
    <input id="singleFile" type="file" class="file-input" [disabled]="false" (change)="onFileSelected($event)" #fileUpload />
    <button  [disabled]="assetUploadBtnFlag" class="btn-upload" 
            (click)="fileUpload.click()" mat-raised-button color="primary">
            Upload <mat-icon inline=true>attach_file</mat-icon>
    </button>
  </div>

</div>

<mat-tab-group #tabGroup (selectedTabChange)="onChange($event)">
  <!--Image component-----Start -->
  <mat-tab [label]="'Images'" class="tab-title">
   
    <div class="img-container">
      <img [src]="selectedItem.imgUrl" class="img-align">
      <div class="sub-heading">
        <div style="float:left">
        Uploaded By : <span class="font-weight">{{selectedItem.CreatedBy}}</span> &nbsp; &nbsp; &nbsp; Uploaded Date : <span class="font-weight">{{selectedItem.CreatedDate | date:'yyyy-MM-dd'}}</span>
      </div>
      <div style="float:right;margin-top:-15.5px;">
        <button class="cancel-icon-cls" mat-icon-button  *ngIf="!hideButton" (click)="deleteAsset(selectedItem)">
          <mat-icon class="icon-font-size" style="color:red; margin-top: 10px;">delete</mat-icon>
        </button>
      </div>
      </div>
    </div>

    <div class="tag-container" style="overflow: auto;">
      <div class="thumbnail-wraper">
        <div class="img-description">
          <div class="asset-area">
            <div class="brdr" [class.selected]="d === selectedItem" *ngFor="let d of sourceData" (click)="displayData(d)">
              <img [src]="d.imgUrl" class="sub-image">
              <div class="user-details">
                <button class="cancel-icon-cls" mat-icon-button  *ngIf="!hideButton" (click)="deleteAsset(d)"><mat-icon class="icon-font-size" style="color:red;margin-bottom: 20px;">delete</mat-icon></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <div class="tag-div-container image-tag-div-size">
       <div class="item-tag-div"> 
        <div class="scrollmenu">
        <mat-chip-list #chipList aria-label="Tag selection" class="asset-tag">
          <mat-chip *ngFor="let entityAssetTag of entityAssetTags" (removed)="remove(entityAssetTag)">
            {{entityAssetTag.tagName}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
      </div>
    </div>
      <div class="image-tag-button-div">
        <button class="entity-btn-add" mat-raised-button color="primary" [disabled]="assetAddTagBtnFlag" (click)="addTag()" tabindex="1">Add Image Tag</button>
      </div>
   
  </mat-tab>
  <mat-tab [label]="'Documents'" class="tab-title">
   
          <div class="document-asset-area">
           
            <dx-data-grid
    [dataSource]="dataSource"
    [showBorders]="true"
    [remoteOperations]="true"
    keyExpr="id"
    [cacheEnabled]="true" 
    [repaintChangesOnly]="true">
    
    <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
    <dxo-editing
    mode="popup"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [useIcons]="true"
    [refreshMode]="reshape"
  >
 
</dxo-editing>
  <dxi-column
    caption="File Type"
    [width]="70"
    [allowFiltering]="false"
    [allowSorting]="false"
    cellTemplate="cellTemplate"
    [calculateCellValue]="GetIconURL"
    
  ></dxi-column>
  
  <dxi-column dataField="originalUrl" [visible]="false" ></dxi-column>
  
    <dxi-column dataField="name"  [width]="600"  caption="Name"></dxi-column>
    <dxi-column   [width]="80"  [calculateCellValue]="GetFileSize"  caption="File Size"></dxi-column>
    <dxi-column dataField="createdBy"   caption="Uploaded By"></dxi-column>
    <dxi-column dataField="createdDate"  [width]="120" caption="Uploaded Date" dataType="date"></dxi-column>
    <dxi-column  type="buttons" caption="Tags" >
      <dxi-button name="edit"
           
      hint="Manage Tags"
     icon="tags" 
     [onClick]="TagsClicked"
      >
    </dxi-button>
    </dxi-column>
    <dxi-column caption="Action"  type="buttons" >
       
           <dxi-button name="view"
           
            hint="Download"
           icon="download"
           [onClick]="downloadFile"
            >
        </dxi-button>
      <dxi-button name="delete" icon="trash" [visible]="!hideButton"  [onClick]="deleteClicked"
      ></dxi-button>
      
    </dxi-column>
    
    <dxo-paging [pageSize]="10"></dxo-paging>  
    <dxo-pager
    [visible]="true"
    
    [displayMode]="displayMode"
    
    
    [showNavigationButtons]="showNavButtons"
  >
  </dxo-pager>
  
    <div *dxTemplate="let data of 'cellTemplate'">
      <img [src]="data.value" style="height:40%;width:40%" />
    </div>
    <dxo-summary [calculateCustomSummary]="calculateSummary">
      <dxi-total-item column="id" summaryType="count"> </dxi-total-item>
      
    </dxo-summary>
</dx-data-grid>



 <dx-popup
 [width]="600"
 [height]="250"
 [showTitle]="true"
 title="Manage Tags"
 [dragEnabled]="false"
 [hideOnOutsideClick]="true"
 [showCloseButton]="true"
 [(visible)]="popupVisible" [deferRendering]="false"
>


    <dxo-position at="center" my="center" [of]="positionOf" collision="fit">
    </dxo-position>
   
    <div *dxTemplate="let data of 'content'">

      <div class="popuptags"  style="height: 100px;">
        <div class="item-tag-div-doc">
          <mat-chip-list #chipList aria-label="Tag selection" class="asset-tag">
            <mat-chip  *ngFor="let entityAssetTag of documentAssetTags" (removed)="removeDocumentTag(entityAssetTag)">
              {{entityAssetTag.tagName}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
        <div >
        <form [formGroup]="tagsForm"  (ngSubmit)="onSubmit()">

          <div >
           
            <mat-form-field>
              <mat-label>New Tag</mat-label>
              <input matInput (cdkAutofill)="tagAutoFill = $event.isAutofilled" formControlName="name" >
              <mat-hint *ngIf="tagAutoFill">Autofilled!</mat-hint>
            </mat-form-field>
            <button class="entity-btn-add" [disabled]="assetAddTagBtnFlag" mat-raised-button color="primary" type="submit">Add</button>
          </div>
          
        </form>
      </div>
      
    </div> 

  
</dx-popup>

    </div>
  </mat-tab>
</mat-tab-group>

<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
</ng-container>


