import { Byte } from "@angular/compiler/src/util";
import { AudioComponent } from "../manage-assets/audio/audio.component";
import { DocumentsComponent } from "../manage-assets/documents/documents.component";
import { VideosComponent } from "../manage-assets/videos/videos.component";
import { Entity } from "./entity.model";
import { EntityAssetTag } from "./entityAssetTag.model";
import { Type } from "./type.model";


export const Tabs = [
    // { label: 'Videos', route: VideosComponent },
    // { label: 'Audio', route: AudioComponent },
    { label: 'Documents', route: DocumentsComponent}
];

export interface EntityAsset {
    active?: boolean;
    createdBy?: string;
    createdDate: string;
    entityId?: number;
    id?: number;
    modifiedBy?: string;
    modifiedDate?: string;
    originalUrl?: string;
    name? :string;
    size? :number;
    extension? :string;
    sampleUrl?: string;
    entityAssetTags : EntityAssetTag[];
    typeId : number;
    typeName? : string;
    sourceData? : Byte[];
}
