import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  videoList = data;

  constructor() { }

  ngOnInit(): void {
  }

}

export const data = [
  {
    imgSource: '../../../assets/images/video-2.jpg',
    title: 'Lorem ipsum dolor sit amet. Est perspiciatis dolorum ea nesciunt'
  },
  {
    imgSource: '../../../assets/images/video-2.jpg',
    title: 'Some Title'
  },
  {
    imgSource: '../../../assets/images/video-2.jpg',
    title: 'Some Title'
  },
  {
    imgSource: '../../../assets/images/video-2.jpg',
    title: 'Some Title'
  },
  {
    imgSource: '../../../assets/images/video-2.jpg',
    title: 'Some Title'
  },
  {
    imgSource: '../../../assets/images/video-2.jpg',
    title: 'Some Title'
  },
  {
    imgSource: '../../../assets/images/video-2.jpg',
    title: 'Some Title'
  },
  {
    imgSource: '../../../assets/images/video-2.jpg',
    title: 'Some Title'
  }
]