import { HttpClient,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  createPatchArray(columnName: string, value: any) {
    return [
      {
        "value": value,
        "path": "/" + columnName,
        "op": "replace"
      }
    ]
  }

  createImageSource(imgUrl: SafeUrl, CreatedBy: string, CreatedDate: string) {
    return [
      {
        "imgUrl": imgUrl,
        "CreatedBy": CreatedBy,
        "CreatedDate": CreatedDate
      }
    ]
  }
}
