import { Component, OnInit } from '@angular/core';
import { EntityAssetService } from '../service/entityAssetService';
import { CommonService } from '../Shared/commonService';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { EntityAsset } from '../model/asset.model';
import { EntityService } from 'src/app/service/entityService';
import { LoadingService } from '../loading.service';
import { HttpStatusCode } from '@angular/common/http';
import { Byte } from '@angular/compiler/src/util';

@Component({
  selector: 'app-view-asset',
  templateUrl: './view-asset.component.html',
  styleUrls: ['./view-asset.component.css']
})
export class ViewAssetComponent implements OnInit {

  loading$ = this.loader.loading$;
  queryString: any;
  urlParams: any;
  assetName: any;
  entityAssetId: any;
  selectedItem: DType = new DType();
  constructor( private router: Router, public loader: LoadingService,private commonService: CommonService,private entityService: EntityService, private entityAssetService: EntityAssetService, private sanitizer: DomSanitizer) {
    
  }

  ngOnInit(): void {
    this.queryString = window.location.search;
    this.urlParams = new URLSearchParams(this.queryString);
    this.entityAssetId = this.urlParams.get('entityAssetId');


    this.entityAssetService.getEntityAsset(this.entityAssetId).subscribe((res: EntityAsset) => {
      this.displayData(res);
    },
    (error: Response) => {  
      if(error.status === HttpStatusCode.BadRequest)  
      {
        this.router.navigate(['./not-found']);
      }
    }
    );



  }


  displayData(entityAsset: any) {
    {
      this.entityService.getSingleImageAsset(entityAsset.originalUrl).then((res) => {
       this.assetName=entityAsset.name;
       if(this.assetName==null)
       {
        this.assetName="image.png";
       }
        var str= this.assetName.split('.');
        var docExtension=str[1];
        var doctype="";
        var Item: DType = new DType();
        switch (docExtension) {
          case "docx":
            doctype="data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
           Item.IsWordOrExcelDocument=true;
              break;
          case "pdf":
            doctype="data:application/pdf;base64,";
            Item.IsPdfDocument=true;
            Item.IsWordOrExcelDocument=false;
            Item.IsVideo=false;
              break;
          case "xlsx":
            doctype="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            Item.IsWordOrExcelDocument=true;
            Item.IsPdfDocument=false;
            Item.IsVideo=false;
              break;
          case "mp4":
            doctype="data:video/mp4;base64,";
            Item.IsVideo=true;
            Item.IsWordOrExcelDocument=false;
            Item.IsPdfDocument=false;
            break;
          default:
           doctype="data:image/png;base64,";
           Item.IsWordOrExcelDocument=false;
           Item.IsVideo=false;
              break;
        }
        Item.doctype=doctype;
        Item.sourceData=res.sourceData;
        Item.name= this.assetName;
        var data = doctype + res.sourceData;
       if(Item.IsVideo)
       {
        Item.imgUrl = this.sanitizer.bypassSecurityTrustUrl(data);
       }
       else
       {
        Item.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
       }

        Item.CreatedBy=entityAsset.createdBy;
        const date = entityAsset.createdDate;
        Item.CreatedDate = new Date(date);
        this.selectedItem = Item;
      });
     
    }
  }

 downloadFile(selectedItem: DType)
 {
  const byteArray= new Uint8Array(
    atob(selectedItem.sourceData).split('').map((char)=>char.charCodeAt(0))
  );
  const file= new Blob([byteArray],{type:selectedItem.doctype});
   const fileURL= URL.createObjectURL(file);
   let fileName=selectedItem.name;
   let link=document.createElement('a');
   link.download=fileName;
   link.target="_blank";
   link.href=fileURL;
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
 }

}

export class DType {
  imgUrl: SafeUrl;
  CreatedBy: any;
  CreatedDate: Date;
  entityAssetId: any;
  originalUrl: any;
  IsPdfDocument: boolean=false;
  IsWordOrExcelDocument:boolean=false;
  IsVideo:boolean=false;
  doctype:any;
  sourceData : any;
  name:any;
}
