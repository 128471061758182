<div class="img-container">
    <img src="{{selectedItem.imgUrl}}" class="img-align">
</div>
<div class="thumbnail-wraper">
    <div class="img-description">
        <input id="singleFile" type="file" [fileUploadInputFor]="fileUploadQueue"  accept="application/pdf,application/msword,
        application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
        <br>

        <mat-file-upload-queue #fileUploadQueue [fileAlias]="'file'">
            <mat-file-upload [file]="file" [id]="i" *ngFor="let file of fileUploadQueue.files; let i = index" [fileAlias]="'file'" [httpUrl]="uploadImage()">
            </mat-file-upload>
        </mat-file-upload-queue>
    </div>
    <div class="img-description bg-color">
        <h4>Tags:</h4>
        <mat-chip-list #chipList aria-label="Tag selection">
            <mat-chip *ngFor="let entityAssetTag of entityAssetTags" (removed)="remove(entityAssetTag)">
                {{entityAssetTag.tagName}}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-list>
        <div>
            <button mat-raised-button color="primary" class="add-tag" (click)="addTag()" tabindex="1">Add Tag</button>
        </div>
    </div>
    <!-- <mat-grid-list cols="3" [gutterSize]="'15px'">
        <mat-grid-tile class="brdr" [class.selected]="d === selectedItem" *ngFor="let d of sourceData" (click)="displayData(d)">
            <img src="{{d.imgUrl}}">
            <div >
                <span>Uploaded By: Rafique Shaikh</span>
                <span>Uploaded Date: 21/01/2022</span>
            </div>
        </mat-grid-tile>
    </mat-grid-list> -->
    <div class="asset-area">
        <div class="brdr" [class.selected]="d === selectedItem" *ngFor="let d of sourceData" (click)="displayData(d)">
            <img src="{{d.imgUrl}}">
            <div class="user-details">
                <li>Uploaded By: </li>
                <li class="font-weight">Rafique Shaikh</li>
                <li>Uploaded Date: </li>
                <li class="font-weight">21/01/2022</li>
                <li>
                    <mat-icon class="delete-ico" (click)="deleteAsset()">delete</mat-icon>
                </li>
            </div>
        </div>
    </div>
</div>