<div class="vdo-container">
    <div class="vdo-wrapper">
        <img src="../../../assets/images/Video.jpg" class="img-align">
    </div>
</div>
<div class="vdo-list-wrapper">
    <div class="vdo-list" *ngFor="let v of videoList">
        <img src="{{v.imgSource}}">
        <div class="title">{{ v.title }}</div>
    </div>
</div>