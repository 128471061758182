import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageAssetsComponent } from './manage-assets/manage-assets.component';
import {CustomErrorComponent} from './custom-error/custom-error.component';
import { VideosComponent } from './manage-assets/videos/videos.component';
import { MsalGuard } from '@azure/msal-angular';
import { NotFoundComponent } from './Shared/not-found/not-found.component';
import { ViewAssetComponent } from './view-asset/view-asset.component';



const routes: Routes = [
  { path: '',  redirectTo: '/manage-assets', pathMatch: 'full'  },
  { path: 'videos', component: VideosComponent },
  { path: 'manage-assets', component: ManageAssetsComponent, 
    canActivate: [ MsalGuard ],
  },
  { path: 'custom-error', component: CustomErrorComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'view-asset', component: ViewAssetComponent,
  canActivate: [ MsalGuard ], },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
