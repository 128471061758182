<div>
<ng-container *ngIf="!selectedItem.IsWordOrExcelDocument && !selectedItem.IsVideo && !selectedItem.IsPdfDocument">
<div class="img-container">
    <img [src]="selectedItem.imgUrl" class="img-align">
    <div class="sub-heading">
      <div style="float:left">
      Uploaded By : <span class="font-weight">{{selectedItem.CreatedBy}}</span> &nbsp; &nbsp; &nbsp; Uploaded Date : <span class="font-weight">{{selectedItem.CreatedDate | date:'yyyy-MM-dd'}}</span>
    </div>
    </div>
  </div>
</ng-container>
  <ng-container *ngIf="selectedItem.IsPdfDocument">
  <div>
    <iframe
    [src]="selectedItem.imgUrl"
    width="100%"
    height="600px"
    loading="lazy"
></iframe>
<div class="sub-heading">
    <div style="float:left">
    Uploaded By : <span class="font-weight">{{selectedItem.CreatedBy}}</span> &nbsp; &nbsp; &nbsp; Uploaded Date : <span class="font-weight">{{selectedItem.CreatedDate | date:'yyyy-MM-dd'}}</span>
  </div>
  </div>
  </div>
  </ng-container>

  <ng-container *ngIf="selectedItem.IsWordOrExcelDocument">
    <div class="file-container" >
      <div class="sub-heading">
        <div style="float:left;margin-top:30px">
        File Name: <span class="font-weight">{{selectedItem.name}}</span> 
      </div>
      </div>
      <div style="clear:both;float:left;margin-top: 30px;" >
       
      
    <button class="entity-btn-add" mat-raised-button color="primary" (click)="downloadFile(selectedItem)">
      Download File
    </button>
  </div>
    <div class="sub-heading">
      <div style="float:left;margin-top: 30px;clear: both;">
      Uploaded By : <span class="font-weight">{{selectedItem.CreatedBy}}</span> &nbsp; &nbsp; &nbsp; Uploaded Date : <span class="font-weight">{{selectedItem.CreatedDate | date:'yyyy-MM-dd'}}</span>
    </div>
    </div>
  </div>
  </ng-container>


  <ng-container *ngIf="selectedItem.IsVideo">
    <div>
        <video width="400" controls>
            <source [src]="selectedItem.imgUrl" type="video/mp4">
          </video>
          <div class="sub-heading">
            <div style="float:left">
            Uploaded By : <span class="font-weight">{{selectedItem.CreatedBy}}</span> &nbsp; &nbsp; &nbsp; Uploaded Date : <span class="font-weight">{{selectedItem.CreatedDate | date:'yyyy-MM-dd'}}</span>
          </div>
          </div>
    </div>
    </ng-container>

  <ng-container *ngIf="loading$ | async">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
  </ng-container>

</div>
