import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EntityAsset } from '../model/asset.model';
import { BlobServiceClient, ContainerClient} from '@azure/storage-blob'
import { EntityAssetTag } from '../model/entityAssetTag.model';
import { AppHttpHeader } from './app-http-header';
import  {  HttpHeaders,  HttpRequest ,HttpResponse }  from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EntityAssetService {

  private readonly serviceUrl = `${environment.WEBAPIURL}/api/EntityAsset`
  private readonly tagServiceUrl = `${environment.WEBAPIURL}/api/EntityAssetTag`
  constructor(private http: HttpClient) { }
  appReqHeader = new AppHttpHeader();
  public getEntityAsset(id: number): Observable<EntityAsset> {
    var requestHeaderDetails = new HttpHeaders({"Ocp-Apim-Subscription-Key":environment.AssetMaintenancePostSubscriptionKey});
    return this.http.get<EntityAsset>(`${this.serviceUrl}/${id}`,{ headers: requestHeaderDetails})
  }

  public getEntityAssetByEntityId(id: number,pagenumber:number,pagesize:number,sortfield:string,sortorder:string):Observable<any> {
    var requestHeaderDetails = new HttpHeaders({"Ocp-Apim-Subscription-Key":environment.AssetMaintenancePostSubscriptionKey}
    );
     
    return this.http.get<EntityAsset>(`${this.serviceUrl}/Assets/${id}/?PageNumber=${pagenumber}&PageSize=${pagesize}&SortField=${sortfield}&SortOrder=${sortorder}`,{ headers: requestHeaderDetails})
  }

  public getPatchEntityAsset(id: Number, data: object, appRegion: string): Observable<EntityAsset> {
   
    var requestHeaderDetails = new HttpHeaders({'AppRegion': appRegion});
    requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);
   
      return this.http.post<any>(`${this.serviceUrl}/${id}`, data, { headers: requestHeaderDetails})
  }

  public searchEntity(data: any): Observable<any>{

    return this.http.post<any>(`${this.serviceUrl}/Search`, data)
  }


  public UpdateEntityAssetTag(data: object): Observable<any> {
    var requestHeaderDetails=this.appReqHeader.Headers;
    requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);
    return this.http.post<any>(this.tagServiceUrl , data, { headers:requestHeaderDetails })
  }
  
  public DeleteEntityAssetTag(entityAssetTagId : string): Observable<any> {
    var requestHeaderDetails=this.appReqHeader.Headers;
    requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);
    return this.http.delete<any>(`${this.tagServiceUrl}/${entityAssetTagId}`, { headers: requestHeaderDetails })
  }

}
