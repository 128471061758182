import { HttpClient,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EntityAsset } from '../model/asset.model';
import { BlobServiceClient, ContainerClient} from '@azure/storage-blob'
import { Entity } from '../model/entity.model';
import { AppHttpHeader } from './app-http-header';
import { EntityTag } from '../model/entityTag.model';
import { AddEntityAsset } from '../model/addEntityAsset.model';
import { AccessDetails } from '../model/acessDetails.model';
import { access } from 'fs';
import  {  HttpHeaders,  HttpRequest  }  from  '@angular/common/http';
import { EntityCategory } from '../model/entityCategory.model';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  private readonly serviceUrl = `${environment.WEBAPIURL}/api/Entity`
  private readonly tagServiceUrl = `${environment.WEBAPIURL}/api/EntityTag`
  private readonly entityAssetUrl = `${environment.WEBAPIURL}/api/EntityAsset`
  private readonly accessUrl = `${environment.WEBAPIURL}/api/OracleAccess`
  constructor(private http: HttpClient) { }
  appReqHeader = new AppHttpHeader();

  public getEntitySourceAsset(entitySourceId: string, source: string, entityType : string,site : string): Observable<Entity>{

    const token = localStorage.getItem('token');

    
    var requestHeaderDetails=this.appReqHeader.Headers;
    requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);
    requestHeaderDetails=requestHeaderDetails.append('Authorization',`Bearer ${token}`);

    return this.http.get<Entity>(`${this.serviceUrl}/${entitySourceId},${source},${entityType},${site}`, {headers: requestHeaderDetails});

  }


  public getEntityCategory(source: string, entityType : string): Observable<EntityCategory>{

    const token = localStorage.getItem('token');
    
    var requestHeaderDetails=this.appReqHeader.Headers;
    requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);
    requestHeaderDetails=requestHeaderDetails.append('Authorization',`Bearer ${token}`);

    return this.http.get<EntityCategory>(`${this.serviceUrl}/${source},${entityType}/EntityCategory`, {headers: requestHeaderDetails});

  }

  public getSingleImageAsset(pictureUrl: number) {
    var requestHeaderDetails= new HttpHeaders({'Ocp-Apim-Subscription-Key':environment.AssetMaintenancePostSubscriptionKey});
    return this.http.get<EntityAsset>(`${this.entityAssetUrl}/${pictureUrl}`,{ headers: requestHeaderDetails }).toPromise();
  }
  
  public UpdateEntity(data: object): Observable<Entity> {
    var query = "";
     {
      query = "/" + 1;
    }
    var requestHeaderDetails=this.appReqHeader.Headers;
    requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);

    return this.http.patch<any>(this.serviceUrl + query , data, { headers: requestHeaderDetails })
}

public UpdateEntityTag(data: object): Observable<any> {
  var requestHeaderDetails=this.appReqHeader.Headers;
  requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);

  return this.http.post<any>(this.tagServiceUrl , data, { headers: requestHeaderDetails })
}

public DeleteEntityTag(entityTagId : string): Observable<any> {
  var requestHeaderDetails=this.appReqHeader.Headers;
  requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);

  return this.http.delete<any>(`${this.tagServiceUrl}/${entityTagId}`, { headers: requestHeaderDetails })
}

public addEntity(data: AddEntityAsset): Observable<Entity>{
  
  var requestHeaderDetails= new HttpHeaders({'Ocp-Apim-Subscription-Key':environment.AssetMaintenancePostSubscriptionKey});
  
  return this.http.post<any>(`${this.serviceUrl}`, data,{headers: requestHeaderDetails})
}

 
public getAccessdetails(entityName: string, userSite:string, appRegion: string): Observable<any>{

  const params = new HttpParams()
    .set('entityName', entityName)
    .set('site', userSite);
    
  var requestHeaderDetails = new HttpHeaders({'AppRegion': appRegion});
  requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);
return this.http.get<any>(`${this.accessUrl}`, {headers: requestHeaderDetails, params});

}

public getOracleEntity(entityName: string,site : string,itemNum : string, appRegion: string, nlsLanguage: string): Observable<any>{

  const params = new HttpParams()
  .set('entityName', entityName)
  .set('site',site)
  .set('itemNumber',itemNum);

  var requestHeaderDetails  = new HttpHeaders({'AppRegion': appRegion})
  requestHeaderDetails =requestHeaderDetails.append("NLSLanguage",nlsLanguage);
  requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);

  return this.http.get<any>(`${this.accessUrl}/${'OracleEntityDetails'}`, {headers: requestHeaderDetails, params});

}

public uploadEntityAsset(entityId: any, data: any, appRegion: string): Observable<any> {

  var returnDigitalAssetBinary : boolean = true;

  var requestHeaderDetails = new HttpHeaders({'AppRegion': appRegion});
  requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",environment.AssetMaintenancePostSubscriptionKey);
 
  return this.http.post<any>(`${this.serviceUrl}/${entityId}/${'DigitalAsset'}/${returnDigitalAssetBinary}`, data, { headers: requestHeaderDetails})
}

}
