  
   
  <p>
    <mat-toolbar color="primary" class="main-header">
        <mat-icon>menu</mat-icon>
      <span class="alcoa-logo">Alcoa | Digital Asset Service</span>
      <span class="example-spacer"></span>
        <!-- <mat-icon class="rt-nav">sync</mat-icon>
        <mat-icon class="rt-nav">monitor</mat-icon> -->
        <mat-icon class="user rt-nav">person</mat-icon>
        <span class="user-info">{{ userName }}</span>
    </mat-toolbar>
  </p>
