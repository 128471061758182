export const environment = {
  production: false,
redirectUrl: 'https://dasaua.devapps.alcoa.com',
  //redirectUrl: 'http://localhost:4200',
  
  clientId: '27a29eb3-cb6d-49b6-9733-fee3067c161c',
  tenantId: '37a72eb7-1faf-49d3-9ddc-6b923b751bc0',
  authority: 'https://login.microsoftonline.com/37a72eb7-1faf-49d3-9ddc-6b923b751bc0/',
  WEBAPIURL: 'https://devapi.alcoa.com/Alcoa.Das/v1',
//WEBAPIURL: 'https://localhost:44370',
  //WEBAPIURL: 'https://localhost:44370',
  consentScopes: ["user.read"],
  scope: ["api://c218f03e-beb3-4212-ab54-34152fe77f1a.alcoa.com/user_impersonation"],
  AssetMaintenancePostSubscriptionKey: "88677f28012e41c4af2a0968ce34ccb9",
  FileUploadSize:20971520
};

