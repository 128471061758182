<mat-dialog-content>
    <div *ngIf="isAddTag">
        <mat-form-field appearance="fill">
            <mat-label>Add Item Tag</mat-label>
            <input matInput [(ngModel)]="name">
          </mat-form-field>
    </div>
	<div *ngIf="isAddAssetTag">
        <mat-form-field appearance="fill">
            <mat-label>Add Tag</mat-label>
            <input matInput [(ngModel)]="name">
          </mat-form-field>
    </div>
	<p *ngIf="!(isAddTag || isAddAssetTag)">
		{{message}}
	</p>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="(isAddTag || isAddAssetTag)">
	<button mat-raised-button color="primary"  (click)="onConfirmClick(name)">{{confirmButtonText}}</button>
	<button mat-raised-button mat-dialog-close [mat-dialog-close]="false" >{{cancelButtonText}}</button>
</mat-dialog-actions>
<mat-dialog-actions align="center" *ngIf="!(isAddTag || isAddAssetTag)">
	<button mat-raised-button color="primary"  (click)="onConfirmClick(true)">{{confirmButtonText}}</button>
	<button mat-raised-button mat-dialog-close [mat-dialog-close]="false" >{{cancelButtonText}}</button>
</mat-dialog-actions>