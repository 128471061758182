import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFileUploadQueueService } from 'angular-material-fileupload/lib/mat-file-upload-queue/mat-file-upload-queue.service';
import { EntityAsset } from 'src/app/model/asset.model';
import { EntityAssetTag } from 'src/app/model/entityAssetTag.model';
import { EntityAssetService } from 'src/app/service/entityAssetService';
import { environment } from 'src/environments/environment';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { MatFileUploadModule } from 'angular-material-fileupload';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  private readonly serviceUrl = `${environment.WEBAPIURL}/api/EntityAsset`
  sourceData = data;
  selectedItem: DType;
  isVisible = false;
  entityAssetTags : EntityAssetTag [];
  entityAssetId : number;
  @Input() entityAssets : EntityAsset [];
  @Input() entityId : number;
  tempPatchArrayObj : any ;
  entityAssetObjlist : any ;
  uploadedFiles : any;
  selectedFilename : any;
  selectedFile : any;
  fruits = [{name: 'Lemon'}, {name: 'Lime'}, {name: 'Apple'}, {name: 'Lemon'}, {name: 'Lime'}, 
          {name: 'Apple'}, {name: 'Lemon'}, {name: 'Lime'}, {name: 'Apple'}, {name: 'Lemon'}, {name: 'Lime'}, {name: 'Apple'}]


  constructor(public dialog: MatDialog,
              private entityAssetService: EntityAssetService , private httpUpload : HttpClient) { }

  ngOnChanges(changes : SimpleChanges) : void {

    this.entityAssetObjlist = changes['entityAssets'].currentValue;
    if (this.entityAssetObjlist)
    {
      this.entityAssetId = this.entityAssetObjlist[0].id;
      if (this.entityAssetId)
      {
      this.entityAssetService.getEntityAsset(this.entityAssetId).subscribe((res: EntityAsset) => {
        this.entityAssetTags = res.entityAssetTags;
      });
     }
    }
    
  }

  ngOnInit(): void {
    this.selectedItem = this.sourceData[0];
    const body = [

      {"op": "replace","path": "/UserTags","value": "U-2"},
  
      { "op": "replace", "path": "/SystemTags", "value": "S-2" },
  
      { "op": "replace", "path": "/Active", "value": "false" },
  
       { "op": "replace", "path": "/ModifiedBy", "value": "v-shaikr1" },
  
      { "op": "replace", "path": "/ModifiedDate", "value": "2/9/2022" }
  
  ];

    const data = {"Source":"Oracle","EntityType":"CSI_ITEM_INSTANCES","SourceEntityId":"ITEM0AB2","Tag":"","Type":1}

    this.entityAssetService.searchEntity(data).subscribe(res => {
    })
  }

  displayData(entity: any) {
    this.isVisible = true;
    this.selectedItem = entity
  }

  addTag() {
    const dialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      data:{
        isAddAssetTag: true,
        buttonText: {
          ok: 'Add Tag',
          cancel: 'Cancel'
        }
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result !== false) {
        this.tempPatchArrayObj = [];
        var patchArrayObj = [];
        this.tempPatchArrayObj.push(this.createPatchArray("EntityAssetId", this.entityAssetId));
        this.tempPatchArrayObj.push(this.createPatchArray("TagName", result));
        for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(this.tempPatchArrayObj[i][0]);
        }
      this.entityAssetService.UpdateEntityAssetTag(patchArrayObj).subscribe((res: EntityAssetTag) => {
        this.entityAssetTags.push(res);
      });

      }
    });
  }

  remove(Tag: any): void {
    const dialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      data:{
        isAddAssetTag: false,
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    })
    dialog.afterClosed().subscribe(result => {
      if (result !== false) {
        this.tempPatchArrayObj = [];
        var patchArrayObj = [];
        this.tempPatchArrayObj.push(this.createPatchArray("EntityAssetId", this.entityAssetId));
        this.tempPatchArrayObj.push(this.createPatchArray("EntityAssetTagId", Tag.entityAssetTagId));
        this.tempPatchArrayObj.push(this.createPatchArray("TagName", Tag.tagName));
        for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(this.tempPatchArrayObj[i][0]);
        }
      this.entityAssetService.UpdateEntityAssetTag(patchArrayObj).subscribe((res: EntityAssetTag) => {
        const index = this.entityAssetTags.indexOf(Tag);
        if (index >= 0) {
          this.entityAssetTags.splice(index, 1);
        }
      });
      }
    })
  }

  createPatchArray(columnName: string, value: any) {
    
    return [
      {
        "value": value,
        "path": "/" + columnName,
        "op": "add"
      }
    ]
 
}

  deleteAsset() {
    this.tempPatchArrayObj = [];
        var patchArrayObj = [];
        this.tempPatchArrayObj.push(this.createPatchArray("Id", this.entityAssetId));
        this.tempPatchArrayObj.push(this.createPatchArray("Active", 0));
        for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(this.tempPatchArrayObj[i][0]);
    }       
  }

  uploadImage(){
    let uploadImageAPIPath = '';
    if(this.entityId)
    {
      uploadImageAPIPath = this.serviceUrl + '?entityId=' + this.entityId;

    }
    return uploadImageAPIPath;
   
}
}

export const data = [
  {
    name: 'Lorem ipsum dolor sit amet. Est perspiciatis dolorum ea nesciunt doloribus aut minima vitae est minima quasi? Sed facere deleniti a galisum repellendus vel amet blanditiis aut natus voluptatem ea obcaecati rerum. Aut blanditiis repellat aut quisquam voluptatem sit accusantium nesciunt qui dolorum voluptate vel dolor odio est voluptatum quos et quia distinctio. Quo fugit dolores ad accusantium beatae aut ipsa repudiandae.',
    imgUrl: 'https://appdevauarefdasassetssa.blob.core.windows.net/digitalfiles/original/94090f71-923c-435f-ba37-81028523e76f.png'
  }
];

export class DType {
  name: string;
  imgUrl: string
}

